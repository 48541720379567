import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SearchBar from '../search-bar/search-bar.container';
import MobileSearchBar from '../mobile-search-bar/mobile-search-bar.container';
import useResponsive from '../../helpers/responsive';
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Navigation } from 'swiper';
import _ from 'lodash';

const CarouselSection = (props) => {
    const { images } = props;
    const { isMobile, isTablet } = useResponsive();

    const renderCarouselItem = () => {
        return _.map(images, (image, index) => {
            const url =
                _.get(image, 'desktop_image_item.url') === undefined
                    ? _.get(image, 'desktop_image_item')
                    : _.get(image, 'desktop_image_item.url');
            const alt_text = _.get(image, 'desktop_image_alt_text');
            const has_button = _.get(image, 'desktop_image_has_button');
            const button_url = '/' + _.get(image, 'desktop_image_button_url');
            const button_image =
                _.get(image, 'desktop_button_image_item.url') === undefined
                    ? _.get(image, 'desktop_button_image_item')
                    : _.get(image, 'desktop_button_image_item.url');
            return (
                <>
                    <SwiperSlide key={index}>
                        <div className="container-fluid oct-slider-five container-fluid-adjust">
                            <img className="img-slide" src={url} alt={alt_text} />
                            {has_button && (
                                <div className="action-buttons-container-inner-two">
                                    <a href={button_url}>
                                        {' '}
                                        <img src={button_image} className="action-buttons-full" />
                                    </a>
                                </div>
                            )}
                        </div>
                    </SwiperSlide>
                </>
            );
        });
    };

    const renderCarouselItemMobile = () => {
        return _.map(images, (image, index) => {
            const mobile_url =
                _.get(image, 'mobile_image_item.url') === undefined
                    ? _.get(image, 'mobile_image_item')
                    : _.get(image, 'mobile_image_item.url');
            const mobile_alt_text = _.get(image, 'mobile_image_alt_text');
            return (
                <>
                    <SwiperSlide key={index}>
                        <div className="container-fluid oct-slider-three-mobile">
                            <div className="row">
                                <div className="col-md-12 ps-mobile">
                                    <img
                                        className="img-slide-m"
                                        src={mobile_url}
                                        alt={mobile_alt_text}
                                    />
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </>
            );
        });
    };

    return (
        <>
            <>
                {!isMobile && (
                    <Swiper
                        navigation={true}
                        spaceBetween={50}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 55500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Navigation]}
                        className="desktop-swiper"
                    >
                        {renderCarouselItem()}{' '}
                    </Swiper>
                )}
                {isMobile && (
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 5500,
                            disableOnInteraction: false,
                        }}
                        className="desktop-swiper"
                    >
                        {renderCarouselItemMobile()}{' '}
                    </Swiper>
                )}
            </>
            {!isMobile && (
                <LandscapeSearchBarContainer>
                    <SearchBarContainer>
                        <SearchBar type="Landing" />
                    </SearchBarContainer>
                </LandscapeSearchBarContainer>
            )}
            {isMobile && (
                <MobileSearchBarContainer>
                    <MobileSearchBar isMobile={isMobile} isTablet={isTablet} type="Home" />
                </MobileSearchBarContainer>
            )}
        </>
    );
};

export default CarouselSection;

CarouselSection.propTypes = {
    images: PropTypes.array,
};

const SearchBarContainer = styled.div`
    width: 100%;
    z-index: 2;
`;

const LandscapeSearchBarContainer = styled.div`
    background-color: #243239;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 30px;
`;

const MobileSearchBarContainer = styled.div`
    background-color: #243239;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 30px;
`;
