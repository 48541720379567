/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';

export const FilterIcon = (props) => {
    const { fill, stroke } = props;
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M9.89062 4.125L23.625 4.125"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                />
                <path
                    d="M1.125 4.125H4.52344"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                />
                <path
                    d="M18.0234 11.625L23.625 11.625"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                />
                <path
                    d="M1.125 11.625H12.5156"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                />
                <path
                    d="M7.125 6.375C8.36764 6.375 9.375 5.36764 9.375 4.125C9.375 2.88236 8.36764 1.875 7.125 1.875C5.88236 1.875 4.875 2.88236 4.875 4.125C4.875 5.36764 5.88236 6.375 7.125 6.375Z"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                />
                <path
                    d="M9.67969 19.125L23.625 19.125"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                />
                <path
                    d="M1.125 19.125H4.52344"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                />
                <path
                    d="M7.125 21.375C8.36764 21.375 9.375 20.3676 9.375 19.125C9.375 17.8824 8.36764 16.875 7.125 16.875C5.88236 16.875 4.875 17.8824 4.875 19.125C4.875 20.3676 5.88236 21.375 7.125 21.375Z"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                />
                <path
                    d="M15.375 13.875C16.6176 13.875 17.625 12.8676 17.625 11.625C17.625 10.3824 16.6176 9.375 15.375 9.375C14.1324 9.375 13.125 10.3824 13.125 11.625C13.125 12.8676 14.1324 13.875 15.375 13.875Z"
                    stroke={stroke}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

FilterIcon.propTypes = {
    fill: PropTypes.string,
    stroke: PropTypes.string,
};
