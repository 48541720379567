import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import useResponsive from '../../helpers/responsive';

const VideoSection = (props) => {
    const { videoUrl, marginTopMobile, marginTop, marginBottomMobile, marginBottom } = props;
    const { isMobile } = useResponsive();

    if (!videoUrl) {
        return (
            <EmptySpace
                $marginTopMobile={marginTopMobile}
                $marginTop={marginTop}
                $marginBottomMobile={marginBottomMobile}
                $marginBottom={marginBottom}
                $isMobile={isMobile}
            />
        );
    }

    return (
        <VideoDiv
            $isMobile={isMobile}
            $marginTopMobile={marginTopMobile}
            $marginTop={marginTop}
            $marginBottomMobile={marginBottomMobile}
            $marginBottom={marginBottom}
        >
            <Container maxWidth="lg" className="section-home-oclub-form">
                <VideoContainer>
                    <LazyLoadComponent>
                        <ReactPlayer
                            className="react-player"
                            url={videoUrl}
                            playing={false}
                            width="100%"
                            height={isMobile ? 180 : 740}
                            controls
                        />
                    </LazyLoadComponent>
                </VideoContainer>
            </Container>
        </VideoDiv>
    );
};

export default VideoSection;

VideoSection.propTypes = {
    title: PropTypes.string,
    subHeading: PropTypes.string,
    videoUrl: PropTypes.string,
    isHome: PropTypes.bool,
    isPartners: PropTypes.bool,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    marginTopMobile: PropTypes.number,
    marginBottomMobile: PropTypes.number,
};

const VideoContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 2.5%;
    position: relative;
    z-index: 2;
`;

const VideoDiv = styled.div`
    margin-top: ${(props) => (props.$isMobile ? props.$marginTopMobile : props.$marginTop)}px;
    margin-bottom: ${(props) =>
        props.$isMobile ? props.$marginBottomMobile : props.$marginBottom}px;
    background-color: transparent;
    z-index: -1;
`;

const EmptySpace = styled.div`
    margin-top: ${(props) => (props.$isMobile ? props.$marginTopMobile : props.$marginTop)}px;
    margin-bottom: ${(props) =>
        props.$isMobile ? props.$marginBottomMobile : props.$marginBottom}px;
`;
