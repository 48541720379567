import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { subscribeToNewsletter } from '../../common-reducers/contact-us-reducer/contact-us.actions';

const SubscribeForm = () => {
    const [formData, setFormData] = useState({
        Email: '',
    });

    const [successfullySubmitted, setSuccessfullySubmitted] = useState(null);

    const subscribeFormSubmit = async (e) => {
        e.preventDefault();
        await subscribeToNewsletter(formData.Email);
        setSuccessfullySubmitted(true);
    };

    const ontxtSubscribeEmailChange = (event) => {
        setFormData({ Email: event.target.value });
    };
    const newsletterSignUpTitle = 'Thanks for signing up! We’ll keep in touch.';
    // const resetForm = () => {
    //     setFormData({ txtSubscribeEmail: '' });
    // };

    return (
        <>
            {!successfullySubmitted && (
                <form
                    className="py-4"
                    id="o-club-subscribe-form"
                    onSubmit={subscribeFormSubmit.bind(this)}
                    method="POST"
                >
                    <div className="row">
                        <div className="col-7">
                            <input
                                type="text"
                                id="Email"
                                required
                                name="Email"
                                className="form-control o-club-input-text"
                                placeholder="Email address"
                                value={formData.Email}
                                onChange={ontxtSubscribeEmailChange.bind(this)}
                            />
                        </div>

                        <div className="col-4">
                            <button type="submit" className="green-button">
                                Subscribe
                            </button>
                        </div>
                    </div>
                </form>
            )}
            {successfullySubmitted && <p className="grey-p-oclub">{newsletterSignUpTitle}</p>}
        </>
    );
};

export default SubscribeForm;
