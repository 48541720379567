/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { RoomsContentLoader } from '../common-components';

function LazyFeaturedSpaces(props) {
    const {
        searchAvailable,
        checkRoomTypeAvailabilityPerBuilding,
        getAllPortfolios,
        isHome,
        title,
        type,
        id,
        visibleRooms,
        text,
        city,
        isGrid,
        portfolioId,
    } = props;

    const [searchFinished, setSearchFinished] = useState(false);
    const router = useRouter();

    const { selectedCity } = useSelector((reducers) => reducers.cityReducer);

    useEffect(() => {
        if (!selectedCity) {
            return;
        }
        async function asyncSearchAvailable() {
            await searchAvailable();
            setSearchFinished(true);
        }
        async function asyncSearchAvailablePerBuilding() {
            await checkRoomTypeAvailabilityPerBuilding();
            setSearchFinished(true);
        }

        getAllPortfolios();
        if (type === 'featured_building') {
            asyncSearchAvailablePerBuilding();
        } else {
            asyncSearchAvailable();
        }
    }, [selectedCity]);

    // Safari fix: weird behaviour with anchor tags and lazy loading combo where there would be jumps to wrong location
    // https://github.com/vercel/next.js/issues/11109
    useEffect(() => {
        const path = window.location.hash;
        if (router.pathname === '/find-a-space' && path && _.includes(path, '#')) {
            setTimeout(() => {
                const id = path.replace('#', '');
                const el = window.document.getElementById(id);
                const r = el.getBoundingClientRect();
                window.top.scroll({
                    top: pageYOffset + r.top,
                    behavior: 'smooth',
                });
            }, 1);
        }
    });

    if (!searchFinished) {
        return <RoomsContentLoader isGrid={isGrid} />;
    }

    const LazyLoadFeaturedDefault = dynamic(() => import('./featured-spaces.container'), {
        ssr: false,
        loading: () => <RoomsContentLoader isGrid={isGrid} />,
    });

    const LazyLoadFeaturedBuildings = dynamic(
        () => import('./featured-spaces-building.container'),
        {
            ssr: false,
            loading: () => <RoomsContentLoader isGrid={isGrid} />,
        },
    );

    const LazyLoadFeaturedSimilar = dynamic(() => import('./similar-spaces-in-city.container'), {
        ssr: false,
        loading: () => <RoomsContentLoader isGrid={isGrid} />,
    });

    const LazyLoad = (props) => {
        const { type, ...rest } = props;
        switch (type) {
            case 'featured_building':
                return <LazyLoadFeaturedBuildings {...rest} />;
            case 'featured_similar_spaces':
                return <LazyLoadFeaturedSimilar {...rest} />;

            default:
                return <LazyLoadFeaturedDefault {...rest} />;
        }
    };

    return (
        <>
            {searchFinished && (
                <LazyLoad
                    id={id}
                    visibleRooms={visibleRooms}
                    text={text}
                    city={city}
                    type={type}
                    isHome={isHome}
                    title={title}
                    portfolioId={portfolioId}
                />
            )}
        </>
    );
}

export default LazyFeaturedSpaces;

LazyFeaturedSpaces.propTypes = {
    isHome: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    visibleRooms: PropTypes.number,
    text: PropTypes.string,
    city: PropTypes.object,
    isGrid: PropTypes.bool,
    portfolioId: PropTypes.string,

    checkRoomTypeAvailabilityPerBuilding: PropTypes.func,
    searchAvailable: PropTypes.func,
    getAllPortfolios: PropTypes.func,
};
