import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { boundMethod } from 'autobind-decorator';
import Router from 'next/router';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import styled from 'styled-components';
import _ from 'lodash';
import { IconButton, Grid, Button } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { theme } from '../../../utils/theme';
import { Image } from '../common-components';

const CustomButtonBack = React.forwardRef((props, ref) => (
    <IconButton component={ButtonBack} ref={ref} {...props} />
));

CustomButtonBack.displayName = 'CustomButtonBack';

const CustomButtonNext = React.forwardRef((props, ref) => (
    <IconButton component={ButtonNext} ref={ref} {...props} />
));

CustomButtonNext.displayName = 'CustomButtonNext';
class CityCarousel extends Component {
    static propTypes = {
        cities: PropTypes.object,
        isMobile: PropTypes.bool,
        isTablet: PropTypes.bool,
        title: PropTypes.string,
        type: PropTypes.string,
        paragraph: PropTypes.string,
    };

    @boundMethod
    onCitySelect(cityName) {
        Router.push(`/bookings?city=${cityName}`).then(() => window.scrollTo(0, 0));
    }

    @boundMethod
    onFindaSpaceClick() {
        Router.push('/bookings').then(() => window.scrollTo(0, 0));
    }

    renderCarouselItem() {
        const { cities, isTablet, isMobile } = this.props;
        let captionFontSize = 16;

        return _.map(cities, (city, index) => {
            const bookableSpace = Math.floor(_.get(city, 'bookableSpaces') / 10) * 10;
            const cityName = _.get(city, 'name');
            const hasNewBuildings = _.get(city, 'hasNewBuildings');
            if (isTablet) {
                captionFontSize = 13.3;
            }
            if (isMobile) {
                captionFontSize = 11.7;
            }
            return (
                <SlideWrapper key={index}>
                    <Slide
                        index={index}
                        key={index}
                        classNameVisible={{ opacity: 1 }}
                        classNameHidden={{ opacity: 0 }}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        className="slide-container"
                    >
                        <LazyLoadComponent>
                            <StyledImage
                                src={_.get(city, 'images[0]')}
                                alt={`City of ${cityName}`}
                                width="100%"
                                height="100%"
                                animate
                                isContainerButton
                                isOldImage
                                id={`${cityName}-carousel-button`}
                                onClick={() => this.onCitySelect(cityName)}
                                containerWidth="90%"
                                containerHeight="80%"
                            />
                        </LazyLoadComponent>

                        <StyledParagraph
                            className="city-name"
                            weight="bold"
                            color={theme.white}
                            marginBottom={5}
                        >
                            {cityName}
                        </StyledParagraph>
                        <StyledParagraph size={captionFontSize} marginTop={0}>
                            Over {bookableSpace}+ spaces
                        </StyledParagraph>
                        {hasNewBuildings && <Label>{_.upperCase('New Buildings')}</Label>}
                    </Slide>
                </SlideWrapper>
            );
        });
    }

    renderBackButton(right) {
        return (
            <StyledCustomButtonBack
                id="city-carousel-back"
                key="back"
                aria-label="Back"
                right={right}
            >
                <StyledArrowBackRoundedIcon />
            </StyledCustomButtonBack>
        );
    }

    renderNextButton(right) {
        return (
            <StyledCustomButtonNext
                id="city-carousel-next"
                key="next"
                aria-label="Next"
                right={right}
            >
                <StyledArrowForwardRoundedIcon />
            </StyledCustomButtonNext>
        );
    }

    renderFindaSpaceCarousel() {
        const { isMobile, isTablet, cities, title, paragraph, type } = this.props;
        const nextButtonRight = 9;
        const backButtonRight = 11;
        if (type !== 'FindaSpace') {
            return null;
        }
        let slideCount = 4.3;

        if (isTablet) {
            slideCount = 2.3;
        }
        if (isMobile) {
            slideCount = 1.3;
        }
        return (
            <CarouselContainer id="city-carousel-find-a-space-page">
                <CarouselProvider
                    naturalSlideWidth={1}
                    naturalSlideHeight={1.6}
                    totalSlides={_.size(cities) * 3}
                    visibleSlides={slideCount}
                    orientation="horizontal"
                    className="carousel-wrapper"
                    dragEnabled={isMobile || isTablet}
                    touchEnabled={isMobile || isTablet}
                    infinite
                    isPlaying
                >
                    <div>
                        <StyledHeader4 marginBottom={0} marginTop={56}>
                            {title}
                        </StyledHeader4>

                        <StyledParagraph
                            marginTop={24}
                            marginBottom={24}
                            color={theme.carouselSubheading}
                        >
                            {paragraph}
                        </StyledParagraph>
                    </div>
                    <Slider classNameTray="slider-tray" aria-label="city-carousel">
                        {this.renderCarouselItem()}
                        {this.renderCarouselItem()}
                        {this.renderCarouselItem()}
                    </Slider>
                    <ButtonContainer>
                        {this.renderBackButton(backButtonRight)}
                        {this.renderNextButton(nextButtonRight)}
                    </ButtonContainer>
                </CarouselProvider>
            </CarouselContainer>
        );
    }

    renderLandingCarousel() {
        const { isMobile, isTablet, cities, type } = this.props;
        const nextButtonRight = 11;
        const backButtonRight = 14;
        let slideCount = 3.3;
        let leftGridSize = 3;
        let rightGridSize = 9;
        let marginTop = 75;

        if (type !== 'Landing') {
            return null;
        }
        if (isTablet) {
            slideCount = 2.3;
            leftGridSize = false;
            rightGridSize = 12;
            marginTop = 20;
        }
        if (isMobile) {
            slideCount = 1.3;
            leftGridSize = false;
            rightGridSize = 12;
            marginTop = 0;
        }

        return (
            <Grid container>
                <Grid item xs={leftGridSize}>
                    <HeaderContainer isMobile={isMobile}>
                        <StyledHeader4 marginTop={77} marginBottom={0}>
                            Where is HOMii
                        </StyledHeader4>
                        <StyledParagraph
                            marginTop={24}
                            marginBottom={24}
                            color={theme.carouselSubheading}
                        >
                            You can find one in every buzzing city centre from Jozi to Cape Town!
                        </StyledParagraph>
                        {!isMobile && (
                            <FindASpaceButton
                                id="city-carousel-find-a-space-button"
                                className="green-button"
                                onClick={this.onFindaSpaceClick}
                            >
                                Find a Space
                            </FindASpaceButton>
                        )}
                    </HeaderContainer>
                </Grid>

                <Grid item xs={rightGridSize}>
                    <CarouselProviderContainer
                        isMobile={isMobile}
                        isTablet={isTablet}
                        marginTop={marginTop}
                    >
                        <CarouselProvider
                            naturalSlideWidth={0.9}
                            naturalSlideHeight={1.4}
                            totalSlides={_.size(cities) * 3}
                            visibleSlides={slideCount}
                            orientation="horizontal"
                            dragEnabled={isMobile || isTablet}
                            touchEnabled={isMobile || isTablet}
                            infinite
                            isPlaying
                        >
                            <Slider classNameTray="slider-tray" aria-label="city-carousel">
                                {this.renderCarouselItem()}
                                {this.renderCarouselItem()}
                                {this.renderCarouselItem()}
                            </Slider>
                            <ButtonContainer>
                                {this.renderBackButton(backButtonRight)}
                                {this.renderNextButton(nextButtonRight)}
                            </ButtonContainer>
                        </CarouselProvider>
                    </CarouselProviderContainer>
                </Grid>
            </Grid>
        );
    }

    render() {
        return (
            <>
                {this.renderFindaSpaceCarousel()}
                {this.renderLandingCarousel()}
            </>
        );
    }
}

export const StyledParagraph = styled.p`
    color: ${(props) => props.color};
    margin-top: ${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
    font-size: ${(props) => props.size}px;
    font-weight: ${(props) => props.weight};
`;

const HeaderContainer = styled.div`
    padding-top: ${(props) => (props.isMobile ? '20px' : '80px')};
    margin-bottom: 20px;
`;

const CarouselProviderContainer = styled.div`
    padding-left: ${(props) => (props.isMobile || props.isTablet ? '0px' : '200px')};
    margin-top: ${(props) => `${props.marginTop}px`};
`;

const StyledCustomButtonBack = styled(CustomButtonBack)`
    right: ${(props) => props.right}%;
    margin-top: 20px;
`;

const StyledCustomButtonNext = styled(CustomButtonNext)`
    border: 2px solid ${(props) => props.theme.carouselButtonBorder};
    border-radius: 50%;
    margin-top: 20px;
    right: ${(props) => props.right}%;
`;

const StyledArrowBackRoundedIcon = styled(ArrowBackRoundedIcon)`
    font-size: 32px;
    color: ${(props) => props.theme.carouselButtonBorder};
`;

const StyledArrowForwardRoundedIcon = styled(ArrowForwardRoundedIcon)`
    font-size: 32px;
    color: ${(props) => props.theme.carouselButtonBorder};
`;

const CarouselContainer = styled.div`
    margin-left: ${(props) => props.marginLeft}px;
    margin-top: ${(props) => props.marginTop}px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SlideWrapper = styled.div`
    .slide-container:hover .city-name {
        color: ${(props) => props.theme.primaryLight};
    }

    .focusRing___1airF.carousel__slide-focus-ring {
        outline: none !important;
        pointer-events: none;
    }
`;

export const StyledHeader4 = styled.h4`
    margin-top: ${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
    font-weight: bold;
    font-size: 52px;
`;

const Label = styled.p`
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 55px;
    background-color: ${(props) => props.theme.primaryLight};
    border-radius: 5px;
    padding: 10px;
    color: ${(props) => props.theme.searchButtonText};
    font-size: 12px;
`;

const StyledImage = styled(Image)`
    border-radius: 5px;
    -webkit-filter: drop-shadow(5px 5px 5px ${(props) => props.theme.shadow});
    filter: drop-shadow(5px 5px 5px ${(props) => props.theme.shadow});
    object-fit: cover;
`;

const FindASpaceButton = styled(Button)`
    font-weight: bold;
    margin-top: 42px;
`;

export default CityCarousel;
