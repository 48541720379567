import { connect } from 'react-redux';

import CityCarousel from './city-carousel.component';

function mapStateToProps({ accountReducer, cityReducer }) {
    return {
        isLoading: accountReducer.isLoading,
        cities: cityReducer.cities,
    };
}

export default connect(mapStateToProps, null)(CityCarousel);
