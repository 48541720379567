import PropTypes from 'prop-types';
import React from 'react';
import { Container, Grid } from '@material-ui/core';
import styled from 'styled-components';

import Image from '../common-components/image.component';
import useResponsive from '../../helpers/responsive';

const LivingTypesSection = (props) => {
    const { isMobile, isTablet } = useResponsive();
    const {
        lifeSectionHeaderOne,
        lifeSectionParagraphOne,
        lifeSectionHeaderTwo,
        lifeSectionParagraphTwo,
        lifeSectionIconOne,
        lifeSectionIconOneAlt,
        lifeSectionIconTwo,
        lifeSectionIconTwoAlt,
        lifeSectionImage,
        lifeSectionImageAlt,
    } = props;

    return (
        <StyledContainer className="section-home-oclub-form">
            <Grid container>
                <ImageGridItem item lg={6} md={12} sm={12}>
                    <ImageWrapper $isTablet={isTablet} $isMobile={isMobile}>
                        <Image
                            src={lifeSectionImage}
                            alt={lifeSectionImageAlt}
                            width={1939}
                            height={1504}
                        />
                    </ImageWrapper>
                </ImageGridItem>
                <TextGridItem item lg={6} md={12} sm={12} $isTablet={isTablet} $isMobile={isMobile}>
                    <LivingTypes $isTablet={isTablet}>
                        <LivingTypeWrapper $isTablet={isTablet} $isMobile={isMobile}>
                            <div>
                                <IconContainer>
                                    <Image
                                        src={lifeSectionIconOne}
                                        alt={lifeSectionIconOneAlt}
                                        width={75}
                                        height={61}
                                    />
                                </IconContainer>
                            </div>
                            <TextWrapper $isTablet={isTablet}>
                                <StyledTitle>{lifeSectionHeaderOne}</StyledTitle>
                                <StyledParagraph
                                    dangerouslySetInnerHTML={{ __html: lifeSectionParagraphOne }}
                                ></StyledParagraph>
                            </TextWrapper>
                        </LivingTypeWrapper>
                        <LivingTypeWrapper $isTablet={isTablet} $isMobile={isMobile}>
                            <div>
                                <IconContainer>
                                    <Image
                                        src={lifeSectionIconTwo}
                                        alt={lifeSectionIconTwoAlt}
                                        width={40}
                                        height={40}
                                    />
                                </IconContainer>
                            </div>
                            <TextWrapper $isTablet={isTablet}>
                                <StyledTitle>{lifeSectionHeaderTwo}</StyledTitle>
                                <StyledParagraph
                                    dangerouslySetInnerHTML={{ __html: lifeSectionParagraphTwo }}
                                ></StyledParagraph>
                            </TextWrapper>
                        </LivingTypeWrapper>
                    </LivingTypes>
                </TextGridItem>
            </Grid>
        </StyledContainer>
    );
};

export default LivingTypesSection;

LivingTypesSection.propTypes = {
    lifeSectionHeaderOne: PropTypes.string,
    lifeSectionParagraphOne: PropTypes.string,
    lifeSectionHeaderTwo: PropTypes.string,
    lifeSectionParagraphTwo: PropTypes.string,
    lifeSectionIconOne: PropTypes.string,
    lifeSectionIconOneAlt: PropTypes.string,
    lifeSectionIconTwo: PropTypes.string,
    lifeSectionIconTwoAlt: PropTypes.string,
    lifeSectionImage: PropTypes.string,
    lifeSectionImageAlt: PropTypes.string,
};

const StyledContainer = styled(Container)`
    margin-bottom: 0px;
`;

const ImageGridItem = styled(Grid)`
    z-index: 10;
`;

const TextGridItem = styled(Grid)`
    z-index: 11;
    margin-bottom: ${(props) => (!props.$isTablet && !props.$isMobile ? '75px' : 'unset')};
`;

const LivingTypes = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: ${(props) => (props.$isTablet ? 'unset' : '-60px')};
`;

const LivingTypeWrapper = styled.div`
    display: flex;
    margin-left: ${(props) => (props.$isTablet && !props.$isMobile ? '10%' : 'unset')};
    margin-right: ${(props) => (props.$isTablet && !props.$isMobile ? '10%' : 'unset')};
    margin-top: ${(props) => (props.$isTablet ? '30px' : 'unset')};
    align-items: ${(props) => (props.$isTablet ? 'flex-start' : 'center')};
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    background-color: white;
    border-radius: 4px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => (props.$isTablet ? '20px' : '60px')};
`;

const StyledTitle = styled.h3`
    font-size: 30px;
`;

const StyledParagraph = styled.div`
    & p {
        font-size: 16px;
        margin-top: 12px;
    }
`;

const ImageWrapper = styled.div`
    max-width: ${(props) => (props.$isTablet && !props.$isMobile ? '75%' : 'unset')}!important;
    margin-top: ${(props) => (props.$isMobile ? '50px' : 'unset')};
    margin-left: auto;
    margin-right: auto;
`;
