import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LazyFeaturedSpaces from './lazy-featured-spaces.component';
import { searchAvailable } from '../../common-reducers/room-types-reducer/room-types.action';
import { checkRoomTypeAvailabilityPerBuilding } from '../../common-reducers/room-types-reducer/room-types.action';
import { getAllPortfolios } from '../../common-reducers/portfolio-reducer/portfolio.actions';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            searchAvailable,
            checkRoomTypeAvailabilityPerBuilding,
            getAllPortfolios,
        },
        dispatch,
    );
}

export default connect(null, mapDispatchToProps)(LazyFeaturedSpaces);
