import React, { useState, useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { useRouter } from 'next/router';
import SearchIcon from '@material-ui/icons/Search';
import { Badge, ButtonBase } from '@material-ui/core';
import dynamic from 'next/dynamic';

import { SearchbarDropdown as Dropdown } from '../common-components/index';
import { FilterIcon } from '../svg-icon-components/filter-icon.component';

const defaultCityText = 'Choose Location';
const defaultTermText = 'Monthly or Daily';
const defaultStyleText = 'Private or Shared';

const MobileSearchBar = (props) => {
    const {
        type,
        selectedCity,
        badgeCount,
        setBadgeCountAction,
        onSearchButtonClick,
        coordinates,
        getCoordinates,
        searchAvailable,
        setRoomTypesAction,
        portfolioId,
    } = props;
    const router = useRouter();
    const { query } = router;
    const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
    const [filterBadgeCount, setFilterBadgeCount] = useState(badgeCount);
    const [city, setCity] = useState(defaultCityText);

    const cityParam = _.get(query, 'city');

    useEffect(() => {
        getQueryParams();
    }, [selectedCity]);

    useEffect(() => {
        if (cityParam === 'Nearby') {
            if (coordinates.latitude && coordinates.longitude && coordinates.isBrowser) {
                searchAvailable('coordinates', coordinates.latitude, coordinates.longitude);
            } else {
                setRoomTypesAction({});
            }
        }
    }, [coordinates]);

    useEffect(() => {
        checkBadgeCount();
    }, [query]);

    const MobileFilterDialog =
        type === 'Bookings'
            ? dynamic(() => import('../mobile-filter-dialog/mobile-filter-dialog.container'), {
                  ssr: false,
              })
            : null;

    const checkBadgeCount = () => {
        const { term, style, building, category, guestCount, priceMin, priceMax } = query;
        const newRoom = _.get(query, 'new');
        let count = 0;

        if (term && term !== defaultTermText) {
            count += 1;
        }
        if (style && style !== defaultStyleText) {
            count += 1;
        }

        if (building) {
            if (typeof building === 'string') {
                count += 1;
            } else {
                const buildingCount = _.size(building);

                count += buildingCount;
            }
        }
        if (category) {
            if (typeof category === 'string') {
                count += 1;
            } else {
                const categoryCount = _.size(category);
                count += categoryCount;
            }
        }
        if (guestCount && guestCount !== 'Any') {
            count += 1;
        }

        if ((priceMin && priceMin !== '400') || (priceMax && priceMax !== '6000')) {
            count += 1;
        }

        if (newRoom) {
            count += 1;
        }

        setFilterBadgeCount(count);
        setBadgeCountAction(count);
    };

    const onCityChange = async (value) => {
        const { onDropdownCityChange, setCurrentCityAction, setSearchTermAction } = props;
        setCity(value);
        setCurrentCityAction(value);

        if (type !== 'Home') {
            setSearchTermAction({ selectedCity: null });
            if (value === 'Nearby') {
                if (!(coordinates.latitude || coordinates.longitude) && !coordinates.isBrowser) {
                    getCoordinates();
                }
            }

            router.push(`/bookings?city=${value}`);
        }

        if (onDropdownCityChange) {
            onDropdownCityChange(value);
        }
    };

    const onSearchClick = () => {
        const { setSearchTermAction } = props;
        setSearchTermAction({ selectedCity: null });
        const query = portfolioId
            ? `/bookings?city=${city}&portfolioId=${portfolioId}`
            : `/bookings?city=${city}`;
        router.push(query);

        if (onSearchButtonClick) {
            onSearchButtonClick();
        }
    };

    const onFilterClick = () => {
        setIsFilterDialogOpen(true);
    };

    const getQueryParams = () => {
        if (selectedCity) {
            if (selectedCity === 'Nearby') {
                setCity(selectedCity);
                if (!(coordinates.latitude || coordinates.longitude) && !coordinates.isBrowser) {
                    getCoordinates();
                }
            } else {
                setCity(selectedCity.name);
            }
        } else if (cityParam && city !== cityParam) {
            setCity(cityParam);
        }
    };

    const buildCityOptions = () => {
        const { cities } = props;

        const sortedCities = _.sortBy(cities, ['name']);
        return _.map(sortedCities, (_city) => {
            const cityOpt = _city;
            cityOpt.label = cityOpt.name;
            cityOpt.value = cityOpt.name;
            return cityOpt;
        });
    };

    const getDefaultOptions = (queryParam, Options) => {
        let defaultOptions = {};
        _.forEach(Options, (options) => {
            if (options.value === queryParam) {
                defaultOptions = options;
            }
        });
        return defaultOptions;
    };

    const renderCityItems = () => {
        const cityOptions = buildCityOptions();
        cityOptions.unshift({ id: 'nearby', value: 'Nearby', label: 'Nearby' });
        const defaultOptions = getDefaultOptions(city, cityOptions);

        return (
            <SearchbarContentWrapper>
                <SearchbarHeadings>Location</SearchbarHeadings>
                <Dropdown
                    type={type}
                    options={cityOptions}
                    onChange={onCityChange}
                    placeholder={defaultCityText}
                    defaultSelected={defaultOptions}
                    selectId="city_dropdown"
                />
            </SearchbarContentWrapper>
        );
    };

    const renderMobileHome = () => {
        if (type !== 'Home' && type !== 'Filters' && type !== 'Portfolio') {
            return null;
        }

        return (
            <Container maxWidth={800} type={type}>
                <Row>
                    <ColLast size={2}>{renderCityItems()}</ColLast>
                    <Col button size={1}>
                        <SearchButton
                            id="search_button"
                            bgcolor={props.theme.primaryLight}
                            startIcon={<SearchIcon />}
                            onClick={onSearchClick}
                        >
                            Search
                        </SearchButton>
                    </Col>
                </Row>
            </Container>
        );
    };

    const renderMobileBookings = () => {
        if (type !== 'Bookings') {
            return null;
        }

        return (
            <Container maxWidth={800}>
                <Row>
                    <ColLast size={2}>{renderCityItems()}</ColLast>

                    <FilterButton
                        id="search_button"
                        onClick={onFilterClick}
                        bgcolor={props.theme.primaryLight}
                    >
                        <StyledBadge badgeContent={filterBadgeCount}>
                            <FilterIcon fill="transparent" stroke="white" />
                        </StyledBadge>
                    </FilterButton>
                </Row>
            </Container>
        );
    };

    const renderFilterDialog = () => {
        if (type !== 'Bookings') {
            return null;
        }

        return <MobileFilterDialog isOpen={isFilterDialogOpen} setIsOpen={setIsFilterDialogOpen} />;
    };

    return (
        <>
            {renderMobileHome()}
            {renderMobileBookings()}
            {renderFilterDialog()}
        </>
    );
};

const SearchbarContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
`;

const Container = styled.div`
    position: relative;
    z-index: 1;
    background: ${(props) => props.theme.black};
    max-width: ${(props) => props.maxWidth}px;
    margin: 0 ${(props) => props.marginHorizontal}px;
    text-align: left;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    height: 80px;
    margin-top: ${(props) => (props.type === 'Home' ? '0px' : '0px')};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Col = styled.div`
    background-color: ${(props) => (props.bgColor ? props.theme.primaryLight : 'none')};
    display: flex;
    flex-direction: column;
    flex: ${(props) => props.size};
    height: 80px;
    justify-content: center;
    &:hover {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-bottom: 2px solid ${(props) => (props.button ? 'none' : props.theme.primaryLight)};
    }
`;

const ColLast = styled(Col)`
    align-items: flex-start;
    padding-left: 20px;
`;

const SearchbarHeadings = styled.span`
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.015em;
    margin-top: 10px;
    margin-bottom: -10px;
    margin-left: 1px;
    color: ${(props) => props.theme.searchBarHeadings};
`;

const SearchButton = styled(Button)`
    background-color: ${(props) => props.bgcolor};
    flex: 1;
    color: ${(props) => props.theme.searchButtonText};
    padding: 7px 14px;
    &:hover {
        background-color: ${(props) => props.bgcolor};
    }
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
`;

const FilterButton = styled(ButtonBase)`
    background-color: ${(props) => props.bgcolor};
    flex: 1;
    color: ${(props) => props.theme.searchButtonText};
    padding: 7px 14px;

    background-color: ${(props) => props.bgcolor};

    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    .MuiTouchRipple-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
`;

const StyledBadge = styled(Badge)`
    .MuiBadge-anchorOriginTopRightRectangle {
        margin-right: -5px;
        color: ${(props) => props.theme.primaryLight};
    }

    .MuiBadge-badge {
        background-color: ${(props) => props.theme.paragraphLight};
    }
`;

export default withTheme(MobileSearchBar);

MobileSearchBar.propTypes = {
    cities: PropTypes.object.isRequired,
    type: PropTypes.string,
    selectedCity: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    coordinates: PropTypes.object,
    badgeCount: PropTypes.number,
    theme: PropTypes.object,
    portfolioId: PropTypes.string,

    setSearchTermAction: PropTypes.func,
    setCurrentCityAction: PropTypes.func,
    onDropdownCityChange: PropTypes.func,
    setBadgeCountAction: PropTypes.func,
    onSearchButtonClick: PropTypes.func,
    getCoordinates: PropTypes.func,
    searchAvailable: PropTypes.func,
    setRoomTypesAction: PropTypes.func,
};
