import React, { useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import useResponsive from '../helpers/responsive';
import { Layout, Footer, MetaData } from '../components/common-components/index';
import { cmsService } from '../services/homii-services';

import styled from 'styled-components';
import getConfig from 'next/config';
import LazyFeaturedSpaces from '../components/featured-spaces/lazy-featured-spaces.container';
import CityCarousel from '../components/city-carousel/city-carousel.container';
import VideoSection from '../components/home-page-components/video-section.component';
import LivingTypesSection from '../components/home-page-components/living-types-section.component';
import CarouselContainer from '../components/common-components/carousel-container.component';
import BlogSection from '../components/home-page-components/blog-section.component';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllCities,
    setSelectedCityByClosestCoordinates,
    setSelectedCityByName,
} from '../common-reducers/city-reducer/city.actions';
import { geolocationService } from '../services/homii-services';
import { setGeoErrorAction } from '../common-reducers/geolocation-reducer/geolocation.reducer';
import CarouselSection from '../components/home-page-components/carousel-section.component';
import OclubForm from '../components/home-page-components/o-club-form.component';

const Index = (props) => {
    const { baseUrlApp, data } = props;

    const { isMobile, isTablet, isSmallDesktop } = useResponsive();
    const dispatch = useDispatch();

    const seoTitle = _.get(data, 'acf.seo_title');
    const seoMetaDesc = _.get(data, 'acf.seo_meta_description');

    const videoTitle = 'Partner Program #1';
    const videoSubHeading = 'KwaMashu with Londiwe Ntuli';
    const videoUrl = 'https://www.youtube.com/watch?v=5VjrKmbhObw';

    const cityTitle = _.get(data, 'acf.city_title');
    const cityParagraph = _.get(data, 'acf.city_paragraph');

    const lifeSectionHeaderOne = _.get(data, 'acf.life_section_header_one');
    const lifeSectionParagraphOne = _.get(data, 'acf.life_section_paragraph_one');
    const lifeSectionHeaderTwo = _.get(data, 'acf.life_section_header_two');
    const lifeSectionParagraphTwo = _.get(data, 'acf.life_section_paragraph_two');
    const lifeSectionIconOne = _.get(data, 'acf.life_section_icon_one.url');
    const lifeSectionIconOneAlt = _.get(data, 'acf.life_section_icon_one_alt');
    const lifeSectionIconTwo = _.get(data, 'acf.life_section_icon_two.url');
    const lifeSectionIconTwoAlt = _.get(data, 'acf.life_section_icon_two_alt');
    const lifeSectionImage = _.get(data, 'acf.life_section_image.url');
    const lifeSectionImageAlt = _.get(data, 'acf.life_section_image_alt');

    const { selectedCity } = useSelector((reducers) => reducers.cityReducer);
    const carousel_section_images = _.get(data, 'acf.carousel_section_images');

    useEffect(() => {
        const getCitiesAndBuildings = async () => {
            await Promise.all([dispatch(getAllCities())]);

            const { publicRuntimeConfig } = getConfig();

            let geolocatedData = null;

            try {
                if (publicRuntimeConfig.HOMII_FEATURE_PAID_GEO_SERVICE) {
                    geolocatedData = await geolocationService.getGeolocationDetails();
                }

                if (!geolocatedData) {
                    geolocatedData = await geolocationService.getGeolocationDetailsFallback();
                }
            } catch (error) {
                dispatch(setGeoErrorAction(error));
            }
            if (!selectedCity) {
                if (_.get(geolocatedData, 'latitude') && _.get(geolocatedData, 'longitude')) {
                    await dispatch(
                        setSelectedCityByClosestCoordinates(
                            geolocatedData.latitude,
                            geolocatedData.longitude,
                        ),
                    );
                } else {
                    await dispatch(setSelectedCityByName('Durban'));
                }
            }
        };
        getCitiesAndBuildings();
    }, [selectedCity]);

    return (
        <Layout>
            <MetaData
                title={seoTitle}
                desc={seoMetaDesc}
                canonical={`${baseUrlApp}`}
                imgSrc="/assets/images/home-page-header.jpeg"
                isHome
            />
            <CarouselSection images={carousel_section_images} />

            <FeaturedSpacesContainer>
                <LazyFeaturedSpaces isHome title="Popular Spaces" />
            </FeaturedSpacesContainer>
            {!isMobile && (
                <div className="container-fluid home-app-section">
                    <div className="row">
                        <div className="col-md-12 padding-zero">
                            <div className="center-home-app">
                                <img
                                    className="home-app-img"
                                    src="/assets/images/home/home-app-img.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 padding-zero">
                            <div className="left-home-app">
                                <a
                                    href="//apps.apple.com/za/app/homii-lifestyle/id1478894335"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <img
                                        src="/assets/images/home/home-app-apple.png"
                                        alt="Apple Store"
                                        className="home-app-download-buttons"
                                        aria-label="apple store"
                                    />
                                </a>
                                <a
                                    href="//play.google.com/store/apps/details?id=za.co.homii.mobile&hl=en"
                                    rel="noreferrer"
                                    target="_blank"
                                    aria-label="google play"
                                >
                                    <img
                                        src="/assets/images/home/home-app-google.png"
                                        alt="Google Play"
                                        className="home-app-download-buttons"
                                    />
                                </a>
                                <a
                                    href="//appgallery.huawei.com/#/app/C102716343"
                                    rel="noreferrer"
                                    target="_blank"
                                    aria-label="huawei appgallery"
                                >
                                    <img
                                        src="/assets/images/home/home-app-huawei.png"
                                        alt="Huawei Store"
                                        className="home-app-download-buttons"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isMobile && (
                <div className="container-fluid home-app-section">
                    <div className="row">
                        <div className="col-md-12 padding-zero">
                            <div className="center-home-app">
                                <img
                                    className="home-app-img"
                                    src="/assets/images/home/homii-app-img-mobile.png"
                                    alt="Downloading unplugged living"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <CarouselContainer isMobile={isMobile} isTablet={isTablet}>
                <CityCarousel
                    isMobile={isMobile}
                    isTablet={isTablet}
                    isSmallDesktop={isSmallDesktop}
                    title={cityTitle}
                    paragraph={cityParagraph}
                    type="Landing"
                />
            </CarouselContainer>
            {!isMobile && (
                <div className="container-fluid section-three-oclub">
                    <div className="container">
                        <h3 className="o-club-reward-text">
                            <span style={{ color: '#bfc849' }}>LIFESTYLE</span>
                            <br />
                            {'&'} <span style={{ color: '#bfc849' }}>REWARDS</span> {'//'}
                            <br />
                            EXCLUSIVELY
                            <br />
                            TO HOMii GUESTS
                        </h3>
                        <h3 className="o-club-reward-text-bottom">
                            Cultivating
                            <br />
                            Future, <span style={{ color: '#bfc849' }}>Family</span>,<br />
                            <span style={{ color: '#bfc849' }}>Fitness</span> ,{' '}
                            <span style={{ color: '#bfc849' }}>Fun</span>
                        </h3>
                    </div>
                </div>
            )}
            {isMobile && (
                <>
                    <div className="container-fluid section-three-oclub-mobile">
                        <div className="container">
                            <h3 className="o-club-reward-text">
                                <span style={{ color: '#bfc849' }}>LIFESTYLE</span>
                                <br />
                                {'&'} <span style={{ color: '#bfc849' }}>REWARDS</span> {'//'}
                                <br />
                                EXCLUSIVELY
                                <br />
                                TO HOMii GUESTS
                            </h3>
                            <h3 className="o-club-reward-text-bottom">
                                Cultivating
                                <br />
                                Future, <span style={{ color: '#bfc849' }}>Family</span>,<br />
                                <span style={{ color: '#bfc849' }}>Fitness</span> ,{' '}
                                <span style={{ color: '#bfc849' }}>Fun</span>
                            </h3>
                        </div>
                    </div>
                </>
            )}
            <OclubForm />

            <VideoSection
                isHome
                title={videoTitle}
                subHeading={videoSubHeading}
                videoUrl={videoUrl}
                isMobile={isMobile}
                marginTop={100}
                marginBottom={150}
            />

            <LivingTypesSection
                lifeSectionHeaderOne={lifeSectionHeaderOne}
                lifeSectionParagraphOne={lifeSectionParagraphOne}
                lifeSectionHeaderTwo={lifeSectionHeaderTwo}
                lifeSectionParagraphTwo={lifeSectionParagraphTwo}
                lifeSectionIconOne={lifeSectionIconOne}
                lifeSectionIconOneAlt={lifeSectionIconOneAlt}
                lifeSectionIconTwo={lifeSectionIconTwo}
                lifeSectionIconTwoAlt={lifeSectionIconTwoAlt}
                lifeSectionImage={lifeSectionImage}
                lifeSectionImageAlt={lifeSectionImageAlt}
            />

            <BlogContainer>
                <CarouselContainer>
                    <BlogSection page={1} showAll={true} tagId={9} pageSize={4} />
                </CarouselContainer>
            </BlogContainer>

            <Footer isMobile={isMobile} isTablet={isTablet} />
        </Layout>
    );
};

export default Index;

Index.getInitialProps = async () => {
    const resp = await cmsService.RetrieveWordPressPage('home');
    return { data: resp };
};

Index.propTypes = {
    baseUrlApp: PropTypes.string,
    clientIp: PropTypes.string,
    data: PropTypes.object,
};

const FeaturedSpacesContainer = styled.div`
    background-color: ${(props) => props.theme.featuredSpacesBackground};
`;

const BlogContainer = styled.div`
    background-color: ${(props) => props.theme.sectionBackgroundDark};
    position: relative;
    z-index: 2;
`;
