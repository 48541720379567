import React from 'react';
import useResponsive from '../../helpers/responsive';
import 'bootstrap/dist/css/bootstrap.min.css';
import SubscribeForm from '../../components/o-club-components/subscribe-form.component';

const OclubForm = () => {
    const { isMobile } = useResponsive();
    const renderCarouselItem = () => {
        return (
            <div className="container-fluid section-home-oclub-form py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img
                                className="img-fluid"
                                src="/assets/images/oclub/Oclub_Logo.png"
                                width="20%"
                            />
                            <h2 className="h2-text py-3">Call me back</h2>
                            <p className="p-text">
                                Please leave your details below and we will call you back to discuss
                                the <br />
                                best membership options for you
                            </p>
                            <div className="row">
                                <div className="col-md-12">
                                    <SubscribeForm />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <p className="p-text">
                                Introducing the O-club and its benefits. HOMii&apos;s will now enjoy
                                the mentorship, financial advice and the stimulation of your
                                entrepreneurship / startup ideas for your personal wealth. HOMii
                                O-Club will further provide advice, assistance and support for all
                                your wealth needs. A host of activities to make the most of your
                                stay with and array of leisure and truly amazing lifestyle session
                            </p>
                            <h2 className="h2-text py-3">It all starts with a VIP membership</h2>
                            <p className="p-text">
                                It&apos;s a brand-new world so we have a range of brand-new ways to
                                stay active. Whether you want to be able to access the club at any
                                time or prefer staying at home and training online or if you want a
                                mix of both, we&apos;ve got you covered. session
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <>
                {!isMobile && <div>{renderCarouselItem()} </div>}
                {isMobile && <div>{renderCarouselItem()} </div>}
            </>
        </>
    );
};

export default OclubForm;
